<template>
  <div
    v-if="company && asset"
    ref="OfferCard"
    style="text-decoration: none;"
    @click="openURL"
  >
    <QCard class="br-25 cursor-pointer bd-1 bd-grey-3">
      <QCardSection>
        <div class="q-gutter-y-sm">
          <div class="flex items-center justify-start no-wrap">
            <CompanyLogo
              v-if="company.name && !mini"
              size="56px"
              :company="company"
              @click.capture.stop="openUrlCompany"
            />
            <div :class="['flex column', {'q-ml-md': !mini}]">
              <div
                v-if="$_.get(company, 'customAttributes.slug') && !mini"
                no-caps
                flat
                unelevated
                :to="{ name: 'companyPage', params: { slug: company.customAttributes.slug } }"
                dense
                class="text-body1-bold text-grey-5 hc-link"
                @click.capture.stop="openUrlCompany"
              >
                {{ company.name }}
              </div>
              <div class="text-body2 text-blue-4">
                {{ offerLocation }}
                <template v-if="offerLocation && offerLocation.includes('bureaux')">
                  <QIcon name="uil:map-marker" />
                  <ActionTooltip>
                    <div
                      v-for="(location, i) in asset.locations"
                      :key="i"
                    >
                      {{ location.city }}
                    </div>
                  </ActionTooltip>
                </template>
              </div>
            </div>
          </div>
          <div
            style="min-height:48px;"
            class="flex column flex-center"
          >
            <div class="full-width ellipsis-2-lines text-body1-bold text-grey-7 text-left">
              {{ asset.name }}
            </div>
          </div>
          <div>
            <div class="flex items-center q-gutter-x-sm">
              <QIcon
                name="uil:file-check"
                class="text-grey-5"
              />
              <div class="text-body2 text-grey-5">
                <span v-if="$_.get(offerCategory, 'name', false)">
                  {{ offerCategory.name }}
                </span>
                {{ $_.get(asset, 'customAttributes.jobSchedule') ? `, ${$t({ id: `customAttributes.jobSchedule.${asset.customAttributes.jobSchedule}` })}` : '' }}
              </div>
            </div>
            <div class="flex items-center q-gutter-x-sm">
              <QIcon
                name="uil:euro-circle"
                class="text-grey-5"
              />
              <div class="text-body2 text-grey-5">
                {{ offerWage ? offerWage : $t({ id: 'component.cards.offer_card.not_filled' }) }}
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <QBadge
              v-if="!mini && companyCategory"
              :label="companyCategory.name"
              class="q-py-xs br-8 text-black bg-blue-3"
            />
            <AppContent
              class="text-caption text-grey-5"
              path="component.cards.offer_card.published_date"
              :options="{ date: fromNow(offerDate) }"
            />
          </div>
        </div>
      </QCardSection>
    </QCard>
  </div>
</template>

<script>
import { fromNow } from 'hc-core/composables/time.js'
import CompanyLogo from 'hc-core/components/common/company-logo'

export default {
  components: { CompanyLogo },
  props: {
    mini: {
      type: Boolean,
      default: false
    },
    asset: { // expecting to be null when loading to show skeleton
      required: true,
      validator: a => typeof a === 'object',
    },
    company: {
      type: Object,
      default: null,
    },
  },
  computed: {
    common () { return this.$store.state.common },
    offerCategory () { return this.$_.get(this.common, `categoriesById[${this.asset.categoryId}]`, null) },
    companyCategory () { return this.$_.get(this.common, `categoriesById[${this.company.categoryId}]`, null) },
    offerDate () {
      return this.$_.get(this, 'asset.platformData.published', null) || this.$_.get(this, 'asset.platformData._v1.published', null) || this.$_.get(this, 'asset.platformData._v1.created', null) || this.$_.get(this, 'asset.createdDate', null)
    },
    offerLocation () {
      let result = null
      if (this.$_.get(this.asset, 'locations', false)) {
        if (this.asset.locations.length > 1) result = `${this.asset.locations.length} bureaux`
        else if (this.$_.get(this.asset, 'locations[0].city', false)) result = this.asset.locations[0].city
      }
      return result
    },
    offerWage () {
      if (this.$_.get(this.asset, 'metadata._job.salaryUndefined', false)) {
        return this.$t({ id: 'component.forms.offerForm.wage.undefined_label' })
      } else if (this.$_.get(this.asset, 'metadata._job.salaryType', null) && this.$_.get(this.asset, 'customAttributes.salaryPeriod', null)) {
        return this.$t({ id: 'component.forms.offerForm.wage.offerStringShort' }, {
          tax: this.$_.get(this.asset, 'metadata._job.salaryTax', null),
          type: this.$_.get(this.asset, 'metadata._job.salaryType', null),
          min: this.$_.get(this.asset, 'customAttributes.salaryMin', null),
          max: this.$_.get(this.asset, 'customAttributes.salaryMax', null),
          period: this.$_.get(this.asset, 'customAttributes.salaryPeriod', null),
        })
      } else return null
    },
  },
  methods: {
    fromNow,
    openURL () {
      let route = { name: 'home' }
      if (this.$_.get(this.company, 'customAttributes.slug', false)) {
        route = { name: 'companyPage', params: { slug: this.company.customAttributes.slug } }
      }
      if (this.$_.get(this.company, 'customAttributes.slug', false) && this.$_.get(this.asset, 'customAttributes.slug', false)) {
        route = { name: 'companyOffer', params: { slug: this.company.customAttributes.slug, assetSlug: this.asset.customAttributes.slug } }
      }
      // Keep actual callbacks or sources or channels if presents
      for (const q of ['callback', 'source', 'channel', 'utm_medium']) {
        this.$_.set(route, `query.${q}`, this.$_.get(this.$route, `query.${q}`, undefined))
      }

      if (process.env.HC_PLATFORM === 'WEB') return this.$router.push(route)
      else return window.open(this.$web(route), '_blank')
    },
    openUrlCompany () {
      const route = { name: 'companyPage', params: { slug: this.company.customAttributes.slug } }
      if (process.env.HC_PLATFORM === 'WEB') return this.$router.push(route)
      else return window.open(this.$web(route), '_blank')
    }
  }
}
</script>
