<template>
  <component
    :is="!!asset ? 'router-link' : 'div'"
    ref="CompanyCard"
    class="anchor-text--reset hc-link"
    :to="computedTo"
  >
    <QCard
      class="relative-position cursor-pointer q-pa-md br-25 bd-1 bd-grey-3"
      style="padding:0;"
    >
      <div :style="coverUrl" />
      <!-- Chips at the top -->
      <div>
        <QChip
          v-if="promoted"
          label="Super cool"
          class="absolute-top-left text-white text-caption q-mt-md q-ml-md bg-gold-4"
          style="z-index:10"
        />
        <QChip
          :label="categoryName"
          class="absolute-top-right text-caption q-mt-md q-mr-md text-black bg-blue-3"
          style="z-index:10"
        />
      </div>

      <!-- Logo -->
      <QCardSection style="height:45px; overflow: visible; transform:translateY(-35px);">
        <CompanyLogo
          class="absolute-center"
          size="96px"
          :company="asset"
        />
      </QCardSection>

      <!-- Statistics -->
      <QCardSection class="q-pt-none">
        <div class="text-center text-grey-7 text-subtitle1 ellipsis">
          {{ asset.name }}
        </div>
        <div
          v-if="computedLocation"
          class="text-center text-body2-bold text-blue-4"
        >
          {{ computedLocation }}
        </div>
        <div class="text-grey-5 text-body2 q-mt-sm">
          <div class="flex items-center q-gutter-sm q-mt-none">
            <QIcon
              size="xs"
              name="uil:users-alt"
            />
            <AppContent
              v-if="computedNb"
              path="component.cards.company_card.members"
              :options="{ nb: computedNb }"
            />
            <AppContent
              v-else
              path="component.cards.company_card.no_members"
              :options="{ nb: computedNb }"
            />
          </div>
          <div class="flex items-center q-gutter-sm q-mt-none">
            <div>
              <QIcon
                size="xs"
                name="uil:venus"
              />
              <span class="q-ml-sm">
                {{ parityRates.woman }}%
              </span>
            </div>
            <div class="q-ml-lg">
              <QIcon
                size="xs"
                name="uil:mars"
              />
              <span class="q-ml-sm">
                {{ parityRates.man }}%
              </span>
            </div>
          </div>
          <router-link
            v-if="asset.offers && $_.get(asset, 'customAttributes.slug', false)"
            class="text-subtitle2 hc-link"
            :to="{name: 'companyOffers', params: { slug: asset.customAttributes.slug }}"
          >
            <div class="flex items-center q-gutter-sm q-mt-none text-primary">
              <QIcon
                size="xs"
                name="uil:analytics"
              />
              <AppContent
                path="component.cards.company_card.offers_running"
                :options="{ nb: asset.offers.length }"
              />
            </div>
          </router-link>
        </div>
      </QCardSection>
    </QCard>
  </component>
</template>

<script>
import { getNoCacheUrl } from 'hc-core/mixins/aws.js'
import { bgGradient } from 'hc-core/composables/misc.js'
import CompanyLogo from 'hc-core/components/common/company-logo'

export default {
  components: { CompanyLogo },
  props: {
    asset: {
      // expecting to be null when loading to show skeleton
      required: true,
      validator: (a) => typeof a === 'object'
    },
    to: {
      validator: (value) => {
        if (this.$_.isNil(value)) return true
        return typeof value === 'string' || typeof value === 'object'
      },
      default: () => null
    },
    promoted: {
      type: Boolean,
      default: false,
    },
    onApp: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isBrand () { return this.asset.assetTypeId === this.$store.getters['common/brandTypeId'] },
    categoryName () {
      if (this.isBrand) return 'Groupe'
      const category = this.$store.state.common.categoriesById[this.asset.categoryId]
      if (category && this.$_.get(category, 'name')) return category.name
      return 'Cabinet'
    },
    coverUrl () {
      let styl = bgGradient(this.$_.get(this.asset, 'id', this.$_.get(this.$route, 'params.slug', 'happycab')))
      // if (this.$_.get(this.asset, 'metadata._files.cover', false)) styl = `background-image:url(${this.cdnImg(this.asset.metadata._files.cover)}); background-position: center;`
      if (this.$_.get(this.asset, 'metadata._files.cover', false)) styl = `background-image:url(${getNoCacheUrl(this.asset.metadata._files.cover)}); background-position: center;`
      return `height:99px; aspect-ratio: 21/6; width:100%; background-color:#F7F7FC; ${styl}; background-size:cover; filter: brightness(50%)`
    },
    parityRates () {
      let manRate = 50
      let womanRate = 50
      if (this.$_.get(this.asset, 'metadata._team')) {
        if (this.$_.get(this.asset, 'metadata._team.parity')) { // Has filled these fields in v2
          womanRate = Math.round(this.asset.metadata._team.parity)
          manRate = 100 - Math.round(this.asset.metadata._team.parity)
        } else { // Just imported from v1
          if (this.$_.get(this.asset, 'metadata._team.womanQty') && this.$_.get(this.asset, 'metadata._team.manQty')) {
            const totalPersons = parseInt(this.asset.metadata._team.womanQty) + parseInt(this.asset.metadata._team.manQty)
            womanRate = Math.round(parseInt(this.asset.metadata._team.womanQty) / totalPersons * 100)
            manRate = 100 - womanRate
          }
        }
      }
      return { woman: womanRate, man: manRate }
    },
    computedLocation () {
      if (this.$_.get(this.asset, 'metadata._public.useOverrideCity', false) && this.$_.get(this.asset, 'metadata._public.overrideCity', '').length) return this.$_.get(this.asset, 'metadata._public.overrideCity', '')
      else if (this.isBrand && this.$_.get(this.asset, 'implantations[0]', false)) return `${this.asset.implantations.length} implantations`
      else if (this.$_.get(this.asset, 'locations[0].city', false)) return this.asset.locations[0].city
      else return false
    },
    computedNb () {
      if (this.$_.get(this.asset, 'metadata._team.displayPeople', false)) {
        if (this.asset.metadata._team.displayPeople.type === 'range' && this.$_.get(this.asset, 'metadata._team.displayPeople.nb')) return this.$t({ id: `component.selectors.headcount_range_selector.${this.$_.get(this.asset, 'metadata._team.displayPeople.nb')}` })
        else return this.$_.get(this.asset, 'metadata._team.displayPeople.nb', false)
      } else return false
    },
    computedTo () {
      if (this.to) return this.to
      else if (!!this.asset && this.$_.get(this.asset, 'customAttributes.slug', false)) {
        const route = { name: 'companyPage', params: { slug: this.asset.customAttributes.slug } }
        return this.onApp ? this.$web(route) : route
      } else return { name: 'notFound' }
    },
  }
}
</script>
