<template>
  <div class="q-gutter-y-sm q-mb-md">
    <QInput
      ref="PwdInput"
      v-model="pwdInput"
      :readonly="readonly"
      class="br-8"
      color="grey-5"
      bg-color="white"
      :label="$t({ id: 'user.password' })"
      :type="inputIsPwd ? 'password' : 'text'"
      :rules="[
        val => !!val || $t({ id: 'form.required' }),
        val => val.length >= 8 || $t({ id: 'user.passwordLength' }),
      ]"
      debounce="500"
      outlined
      bottom-slots
      @update:model-value="stepResult"
    >
      <!-- <template #error>
        <AppContent v-if="pwdInput.length < 8" path="user.passwordLength" class="text-negative" />
      </template> -->
      <template #append>
        <QIcon
          :name="inputIsPwd ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="inputIsPwd = !inputIsPwd"
        />
      </template>
    </QInput>

    <QInput
      ref="PwdConfirm"
      v-model="pwdConfirm"
      :readonly="readonly"
      class="br-8"
      color="grey-5"
      bg-color="white"
      :label="$t({ id: 'user.passwordConfirmation' })"
      :type="confirmIsPwd ? 'password' : 'text'"
      :rules="[
        val => !!val || $t({ id: 'form.required' }),
        val => val.length >= 8 || $t({ id: 'user.passwordLength' }),
        val => val === pwdInput || $t({ id: 'user.passwordDiferent' }),
      ]"
      debounce="500"
      outlined
      bottom-slots
      @update:model-value="stepResult"
    >
      <!-- <template #error>
        <AppContent v-if="pwdConfirm.length > 0 && pwdConfirm.length < 8" path="user.passwordLength" class="text-negative" />
        <AppContent v-if="pwdInput !== pwdConfirm" path="user.passwordDiferent" class="text-negative" />
      </template> -->
      <template #append>
        <QIcon
          :name="confirmIsPwd ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="confirmIsPwd = !confirmIsPwd"
        />
      </template>
    </QInput>
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    light: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      pwdInput: '',
      pwdConfirm: '',
      inputIsPwd: true,
      confirmIsPwd: true,
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.pwdInput = this.$_.get(this.step, `copyFromObject.${this.step.field}`, '')
        this.pwdConfirm = this.$_.get(this.step, `copyFromObject.${this.step.field}`, '')
      } else {
        this.pwdInput = this.$_.get(this.formObject, this.step.field, '')
        this.pwdConfirm = this.$_.get(this.formObject, this.step.field, '')
      }
      this.stepResult()
    })
  },
  mounted () {
    if (!this.light) this.$refs.PwdInput.focus()
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.pwdInput)
      const canGoNext = this.$_.get(this.pwdInput, 'length', 0) > 8 && this.$_.get(this.pwdConfirm, 'length', 0) > 8 && this.pwdInput === this.pwdConfirm
      this.$emit('stepResult', { canGoNext, value })
    }
  },
}
</script>
