<template>
  <QHeader class="bd-1 bd-1-bottom bd-grey-3">
    <div class="bg-white q-py-sm">
      <QToolbar class="bg-white flex items-center justify-between">
        <router-link
          class="logo-container anchor-text--reset cursor-pointer text-primary"
          :to="{ name: 'home' }"
          :aria-label="$t({ id: 'navigation.home' })"
          flat
        >
          <div class="flex items-center">
            <AppLogo />
          </div>
        </router-link>

        <div class="gt-sm flex-items-center">
          <div class="flex flex-center text-grey-6 q-gutter-x-xl no-wrap">
            <router-link
              v-for="menuItem of menuItems"
              :key="menuItem"
              :to="{ name: menuItem }"
              :class="['text-body2 text-medium hc-link cursor-pointer', $route.name === menuItem ? 'text-primary' : 'text-grey-6']"
            >
              {{ $t({ id: `navigation.menu.${menuItem}` }) }}
            </router-link>
          </div>
        </div>

        <div class="flex items-center q-gutter-md gt-sm">
          <!-- <div>
            <LocaleSwitchButton />
            <QBtn flat round :icon="$q.dark.isActive ? 'uil:sun' : 'uil:moon'" color="primary" @click="$q.dark.toggle()" />
          </div> -->
          <template v-if="socialLinks.length">
            <div
              v-for="socialLink of socialLinks"
              :key="socialLink.color"
            >
              <QBtn
                :icon="socialLink.icon"
                :color="socialLink.color"
                :href="socialLink.href"
                :aria-label="socialLink.label"
                target="_blank"
                size="sm"
                class="social-link"
                flat
                dense
              >
                <ActionTooltip :path="`social.${socialLink.label}`" />
              </QBtn>
            </div>
          </template>

          <HCBigButton
            v-for="btn of rightButtons"
            :key="btn.label"
            :color="btn.color"
            :label="btn.label"
          >
            <QMenu>
              <QList style="min-width: 180px">
                <QItem
                  v-for="child of btn.children"
                  :key="child.label"
                  v-close-popup
                  :href="$_.get(child, 'href', undefined)"
                  :to="$_.get(child, 'to', undefined)"
                  active-class="text-primary text-weight-medium"
                  clickable
                  :target="$_.get(child, 'href', false) ? '_blank' : undefined"
                  @click="$_.get(child, 'fn', false) ? child.fn() : ''"
                >
                  <QItemSection>
                    {{ $t({ id: `navigation.menu.${child.label}` }) }}
                  </QItemSection>
                </QItem>
              </QList>
            </QMenu>
          </HCBigButton>
        </div>

        <!-- Mobile menu -->
        <QBtn
          class="lt-md"
          color="dark"
          flat
          round
          dense
          icon="uil:bars"
          @click="mobileDrawer = true"
        />
      </QToolbar>
    </div>

    <!-- Mobile menu -->
    <QDrawer
      v-model="mobileDrawer"
      side="right"
      overlay
      behavior="mobile"
      bordered
    >
      <div
        style="height:100%;"
        class="flex column justify-between q-px-sm"
      >
        <QBtn
          icon="uil:bars"
          flat
          class="absolute-top-right text-black q-mt-md"
          @click="mobileDrawer = false"
        />
        <div class="flex column items-center q-gutter-y-lg q-pt-md">
          <AppLogo />
          <div
            v-for="menuItem of menuItems"
            :key="menuItem"
          >
            <router-link
              :to="{ name: menuItem }"
              :class="['text-body1 text-weight-medium hc-link cursor-pointer', $route.name === menuItem ? 'text-primary' : 'text-grey-6']"
            >
              {{ $t({ id: `navigation.menu.${menuItem}` }) }}
            </router-link>
          </div>

          <template
            v-for="btn of rightButtons"
            :key="btn.label"
          >
            <QSeparator class="q-mt-lg q-px-xl" />
            <div class="text-grey-5 text-uppercase">
              {{ btn.label }}
            </div>
            <QList>
              <QItem
                v-for="child of btn.children"
                :key="child.label"
                v-close-popup
                :href="$_.get(child, 'href', undefined)"
                :to="$_.get(child, 'to', undefined)"
                active-class="text-primary text-weight-medium"
                class="text-black"
                clickable
                :target="$_.get(child, 'href', false) ? '_blank' : undefined"
                @click="$_.get(child, 'fn', false) ? child.fn() : ''"
              >
                {{ $t({ id: `navigation.menu.${child.label}` }) }}
              </QItem>
            </QList>
          </template>
        </div>
        <div class="flex column items-center q-gutter-y-lg q-pb-lg">
          <!-- <div>
            <LocaleSwitchButton />
            <QBtn flat round :icon="$q.dark.isActive ? 'uil:sun' : 'uil:moon'" color="primary" @click="$q.dark.toggle()" />
          </div> -->
          <div
            v-if="socialLinks.length"
            class="flex items-center"
          >
            <div
              v-for="socialLink of socialLinks"
              :key="socialLink.color"
            >
              <QBtn
                :icon="socialLink.icon"
                :color="socialLink.color"
                :href="socialLink.href"
                target="_blank"
                size="md"
                class="social-link"
                flat
                dense
              >
                <ActionTooltip :path="`social.${socialLink.label}`" />
              </QBtn>
            </div>
          </div>
        </div>
      </div>
    </QDrawer>
  </QHeader>
</template>

<script>
import AppLogo from 'hc-core/components/common/app-logo'
// import LocaleSwitchButton from 'hc-core/components/buttons/locale-switch-button'

export default {
  components: { AppLogo },
  data () {
    return {
      query: null,
      mobileDrawer: false,
      menuItems: [
        'searchCompanies',
        'searchOffers',
        'blog',
      ],
    }
  },
  computed: {
    socialLinks () { return this.$_.get(this.$store.state, 'common.config.custom.social', []) },
    rightButtons () {
      const isLoggedIn = !!this.$_.get(this.$store, 'state.auth.naturalUser', null) && !this.$_.isEmpty(this.$_.get(this.$store, 'state.auth.naturalUser', {}))
      return [
        {
          label: 'Cabinet',
          color: 'secondary',
          children: [
            { label: 'signupCompany', href: `${process.env.HC_APP_URL}/?tab=signup&role=client`, if: !isLoggedIn },
            // { label: 'signupCompany', href: this.$app({ name: 'signupCompany' }), if: !isLoggedIn },
            { label: 'login', href: this.$app({ name: 'login' }), if: !isLoggedIn },
            { label: 'account', href: this.$app({ name: 'dashboard' }), if: isLoggedIn },
            { label: 'logout', fn: async () => { await this.logout() }, if: isLoggedIn },
            { label: 'product', to: { name: 'product' } },
            { label: 'pricing', to: { name: 'pricing' } },
          ].filter(t => this.$_.get(t, 'if', true))
        },
        {
          label: 'Candidat',
          color: 'primary',
          children: [
            { label: 'signupApplicant', href: `${process.env.HC_APP_URL}/?tab=signup&role=applicant`, if: !isLoggedIn },
            // { label: 'signupApplicant', href: this.$app({ name: 'signupApplicant' }), if: !isLoggedIn },
            { label: 'login', href: this.$app({ name: 'login' }), if: !isLoggedIn },
            { label: 'account', href: this.$app({ name: 'dashboard' }), if: isLoggedIn },
            { label: 'logout', fn: async () => { await this.logout() }, if: isLoggedIn },
            { label: 'landingRecruit', to: { name: 'landingRecruit' } },
          ].filter(t => this.$_.get(t, 'if', true))
        }
      ]
    }
  },
  methods: {
    async logout () { await this.$store.dispatch('auth/logout') }
  }
}
</script>
